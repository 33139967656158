import { ReactElement, useEffect, useState } from 'react';

interface AuthGuardProps { 
  children: ReactElement | null;
}

// embedded=1&hmac=7bd3e0277bd939b157aa98bb72edc2d756843419762549e0f35dd79c629a43fa&host=a2FybWljaGEubXlzaG9waWZ5LmNvbS9hZG1pbg&locale=en-GB&session=fb0864d2fde4d539a4a9854bafd5eb90b73fda17bc2b6211ad2a65c1c496c94b&shop=karmicha.myshopify.com&timestamp=1669436130

export function AuthGuard({children = null} : AuthGuardProps) {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const run = async () => {
      const url = new URL(window.location.href);
      if (url.searchParams.get('session')) {
        setIsValid(true);
        return;
      }
      if (window.top) {
        window.top.location.href = `/.netlify/functions/install-app`;
        return
      } 
      window.location.href = `/.netlify/functions/install-app`;
    }
    run();
  }, []);

  return isValid ? children : null;
}
