import { Page } from '@shopify/polaris';
import { AuthGuard } from '../AuthGuard';

export function Main() {      
  return (
    <AuthGuard>
      <Page>
        BUDOWA
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/yrJ_lzYGJdg" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
        />
      </Page>
    </AuthGuard>
  )
}