// import { Provider } from "@shopify/app-bridge-react";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/pl.json";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "./pages/Main";
import { NotFound } from "./pages/NotFound";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '*',
    element: <NotFound />
  }
])

export function App() {
  return (
    <AppProvider i18n={translations}>
      <RouterProvider router={router} />
    </AppProvider>
  );
}
